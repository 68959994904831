import React from "react";
import { graphql } from "gatsby";
import ScriptTag from "react-script-tag";

import Layout from "../components/layout";
import SocialShare from "../components/SocialShare";
import { useMixpanelTracker } from "../hooks/useMixpanelTracker";
import { DownloadsJsonLd } from "../components/jsonld/DownloadsJsonLd";

const DownloadTemplate = ({ data, location: { pathname } }) => {
  const DownloadData = data.datoCmsDownload;
  const formId =
    DownloadData.form[0] && DownloadData.form[0].formScript
      ? DownloadData.form[0].formScript.split("id=")[1]
      : undefined;

/*   useMixpanelTracker(
    formId,
    (mixpanel, formData) => {
      mixpanel.alias(formData["email"]);
      mixpanel.identify(formData["email"]);

      mixpanel.track("Download resource", {
        Name: formData["firstname"],
        Email: formData["email"],
        Website: formData["field[2]"],
        Role: formData["field[4]"],
        Resource: window.location.pathname
      });

      mixpanel.people.set({
        $email: formData["email"]
      });

      mixpanel.people.set_once({ $name: formData["firstname"] });
      mixpanel.people.union("downloaded", window.location.pathname);
    },
    undefined,
    [formId]
  ); */

  return (
    <Layout
      headerBgClass="bg-primary-alt"
      hideFooter
      footerBgClass="bg-primary-alt"
      meta={{ seo: DownloadData.seo, path: pathname }}
    >
      <DownloadsJsonLd download={DownloadData} />
      <div className="download-template-page">
        {/*<div className="download-template-page__bg bg-primary-alt has-divider">
          <div className="divider">
            <Divider3 style={{ transform: "matrix(-1, 0, 0, 1, 0, 0)" }} />
          </div>
        </div>*/}

        <section className="bg-primary-alt">
          <div className="container">
            <div className="row">

            <div className="col-12 d-md-none mb-4" id="form">
              <div className="card card--common card-body card--no-hover">
                  <div id="activecampaign-form">
                    <div id="activecampaign-form__wrapper">
                      {DownloadData.form[0] && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DownloadData.form[0].formHtml
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              

              <div className="col-12 col-lg-7 col-md-6">
                <h1 className="h1">{DownloadData.title}</h1>

                <div className="my-3">
                  <SocialShare link={pathname} title={DownloadData.title} />
                </div>

                <div
                  className="description"
                  dangerouslySetInnerHTML={{ __html: DownloadData.description }}
                />
              </div>

              <div className="d-none d-md-block col-lg-5 col-md-6">
                <div className="card card--common card-body card--no-hover">
                  <div id="activecampaign-form">
                    <div id="activecampaign-form__wrapper">
                      {DownloadData.form[0] && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DownloadData.form[0].formHtml
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {DownloadData.form[0] && (
        <ScriptTag
          type="text/javascript"
          src={DownloadData.form[0].formScript}
        />
      )}
    </Layout>
  );
};

export default DownloadTemplate;

export const query = graphql`
  query DownloadTemplate($id: String!) {
    datoCmsDownload(id: { eq: $id }) {
      title
      description
      form {
        formHtml
        formScript
      }
      slug
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
    }
  }
`;

import React from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';

import { PRODUCTION_SITE_URL } from '../constants/common';
import IconSocialFacebook from '../images/icons/social/facebook.svg';
import IconSocialLinkedin from '../images/icons/social/linkedin.svg';
import IconSocialTwitter from '../images/icons/social/twitter.svg';

const SocialShare = ({ link, title }) => {
  const ShareURL = `${PRODUCTION_SITE_URL}${link}`;
  /* const mixpanel = useMixpanel();

  const track = (event) => {
    mixpanel.track(event);
  }; */

  return (
    <div className="social-share py-4">
      <p className="mr-1 d-block">Don't forget to share this post:</p>
      <div className="d-flex mx-2">
        <TwitterShareButton
          url={ShareURL}
          beforeOnClick={() => track('Twitter share')}
        >
          <div className="btn btn-round btn-lg mx-1">
            <IconSocialTwitter className="icon icon-sm" />
          </div>
        </TwitterShareButton>

        <FacebookShareButton
          url={ShareURL}
          beforeOnClick={() => track('Facebook share')}
        >
          <div className="btn btn-round btn-lg mx-1">
            <IconSocialFacebook className="icon icon-sm" />
          </div>
        </FacebookShareButton>

        <LinkedinShareButton
          url={ShareURL}
          beforeOnClick={() => track('LinkdIn share')}
        >
          <div className="btn btn-round btn-lg mx-1">
            <IconSocialLinkedin className="icon icon-sm" />
          </div>
        </LinkedinShareButton>
      </div>
    </div>
  );
};

export default SocialShare;
